import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { Button, Calendar, Card, EventTypes, Form, Link, Stack, Text } from '@teacher-app/design-system';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { usePlannerSettings } from '@hooks';
import { useAppState } from '@context';
import { NoPlanner } from '@teacher-app/pages/Planner';
import dayjs from 'dayjs';
import { API_URL, getApiWithAuth, putApiWithAuth } from '@utils';
import i18n from '@services/i18n';
import RescheduleEventModal from '@teacher-app/components/RescheduleEventModal';
import ResetPlannerModal from '@teacher-app/components/ResetPlannerModal/ResetPlannerModal';
import TutorialVideoModal from '@teacher-app/components/TutorialVideoModal/TutorialVideoModal';

const lang = {
  'en-CA': null,
  'en-US': null,
  'fr-CA': {
    week: 'La semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    month: 'Mois',
    previous: 'Antérieur',
    next: 'Prochain',
    today: "Aujourd'hui",
    agenda: 'Ordre du jour',
    showMore: total => `+${total} plus`
  }
};

const { Option } = Select;

export const Planner = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'planner' });
  const navigate = useNavigate();
  const { state } = useAppState();
  const [form] = Form.useForm();

  const { data: settingData, revalidate: getSettings, settingLoading } = usePlannerSettings(state.classId);

  const [culture] = useState(i18n.language);
  const [myEvents, setMyEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [showRescheduleLessonSpinner, setShowRescheduleLessonSpinner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showResetPlannerModal, setShowResetPlannerModal] = useState(false);
  const [showPlannerTutorial, setShowPlannerTutorial] = useState(false);
  const [holidays, setHolidays] = useState([]);

  const [allEvents, setAllEvents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventsMap, setEventsMap] = useState(new Map());

  const hideRescheduleModal = () => {
    setMyEvents(prev => {
      const filtered = prev.filter(ev => ev.id !== currentEvent.id);
      return [
        ...filtered,
        {
          ...currentEvent,
          start: currentEvent.oldDate,
          end: currentEvent.oldDate,
          date: currentEvent.oldDate,
          allDay: currentEvent.allDay,
          oldDate: null
        }
      ];
    });
    setCurrentEvent(null);
  };

  const rescheduleLesson = async () => {
    setShowRescheduleLessonSpinner(true);
    setShowRescheduleLessonSpinner(true);
    const { success } = await putApiWithAuth(`${API_URL.PLANNER_EVENT}/${state.classId}`, {
      id: currentEvent.id,
      date: dayjs(currentEvent.date).format('YYYY-MM-DD')
    });
    if (success) {
      setShowRescheduleLessonSpinner(false);
      setMyEvents(prev => {
        const filtered = prev.filter(ev => ev.id !== currentEvent.id);
        return [
          ...filtered,
          {
            ...currentEvent,
            start: currentEvent.start,
            end: currentEvent.start,
            date: currentEvent.start,
            oldDate: null,
            allDay: currentEvent.allDay
          }
        ];
      });
      setCurrentEvent(null);
    } else {
      setShowRescheduleLessonSpinner(false);
    }
  };

  const navigateToResource = useCallback(calEvent => {
    if (calEvent.isDraggable) {
      navigate(`/resources/${calEvent.topic_id}?grade=${calEvent.grade.name}`);
    }
  });

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      setMyEvents(prev => {
        const filtered = prev.filter(ev => ev.id !== event.id);
        return [...filtered];
      });

      setCurrentEvent({ ...event, start, end, allDay, date: start, oldDate: event.start });
    },
    [setMyEvents]
  );

  useEffect(async () => {
    getSettings();
    setLoading(true);
    const { success, data } = await getApiWithAuth(`${API_URL.PLANNER_EVENT}/${state.classId}`);
    if (success) {
      if (data) {
        const updatedEvents = data.map(event => ({
          title: `${event.topic_name}: ${event.lesson_name}`,
          allDay: true,
          start: dayjs(event.date, 'YYYY-MM-DD'),
          end: dayjs(event.date, 'YYYY-MM-DD'),
          date: dayjs(event.date, 'YYYY-MM-DD'),
          isDraggable: true,
          isEvent: EventTypes.EDITABLE,
          duration: t('all_day'),
          ...event
        }));
        setMyEvents(updatedEvents);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [state.classId]);

  useEffect(() => {
    if (settingData) {
      const updatedSettings = settingData['off_days'].map(holiday => ({
        title: `${holiday.title}: ${holiday.title}`,
        allDay: true,
        start: dayjs(holiday.date, 'YYYY-MM-DD'),
        end: dayjs(holiday.date, 'YYYY-MM-DD'),
        date: dayjs(holiday.date, 'YYYY-MM-DD'),
        isEvent: EventTypes.HOLIDAY,
        isDraggable: false,
        duration: t('all_day'),
        ...holiday
      }));
      setHolidays(updatedSettings);
    }
  }, [settingData]);

  const { messages } = useMemo(
    () => ({
      messages: lang[culture]
    }),
    [culture]
  );

  const handleClassesChange = values => {
    if (values.length > classes.length) {
      const newItems = values.filter(item => !classes.find(cls => cls === item));
      setClasses(values);
      for (const item of newItems) {
        fetchEvents(item);
      }
    } else {
      const keysSet = new Set(values);

      const newMap = new Map();
      for (const [key, value] of eventsMap) {
        if (keysSet.has(key)) {
          newMap.set(key, value);
        }
      }

      setEventsMap(newMap);
      setClasses(values);
    }
  };

  useEffect(() => {
    setAllEvents(Array.from(eventsMap.values()).flat());
  }, [eventsMap]);

  const fetchEvents = async classId => {
    setLoading(true);
    const { success, data } = await getApiWithAuth(`${API_URL.PLANNER_EVENT}/${classId}`);
    if (success) {
      if (data) {
        const updatedEvents = data.map(event => ({
          title: `${event.topic_name}: ${event.lesson_name}`,
          allDay: true,
          start: dayjs(event.date, 'YYYY-MM-DD'),
          end: dayjs(event.date, 'YYYY-MM-DD'),
          date: dayjs(event.date, 'YYYY-MM-DD'),
          isDraggable: false,
          isEvent: EventTypes.NON_EDITABLE,
          duration: t('all_day'),
          ...event
        }));
        const newMap = new Map(eventsMap);
        newMap.set(classId, updatedEvents);
        setEventsMap(newMap);
      }
      setLoading(false);
    } else {
      const newMap = new Map(eventsMap);
      newMap.set(classId, []);
      setEventsMap(newMap);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
        <AuthenticatedNavbar title={t('planner')} />
        <Stack direction="column" alignItems="flex-start" spacing={30} style={{ marginTop: 30 }}>
          <Row>
            <Col span={13} sm={{ span: 18 }} md={{ span: 16 }}>
              <Text size="small">
                {t('planner_detail_not_found')}&nbsp;
                <Link style={{ display: 'inline-block' }} onClick={() => setShowPlannerTutorial(true)}>
                  {t('planner_detail_watch_tutorial')}
                </Link>
              </Text>
            </Col>
          </Row>
          {loading || settingLoading ? (
            <Card width="100%">
              <Stack direction="column" justifyContent="center" style={{ minHeight: '200px' }}>
                <Spin size="large" />
              </Stack>
            </Card>
          ) : myEvents.length === 0 ? (
            <NoPlanner
              onClick={() => {
                navigate('/planner/new');
              }}
            />
          ) : (
            <Stack direction="column" alignItems="flex-start" spacing={30}>
              <Button onClick={() => setShowResetPlannerModal(true)}>{t('reset_planner_button')}</Button>
              <Form form={form}>
                <Form.Item label={t('select_class')}>
                  <Select
                    mode="multiple"
                    value={classes}
                    placeholder={t('select_class')}
                    optionLabelProp="label"
                    onChange={handleClassesChange}>
                    {state.classes
                      .filter(v => state.classId !== v.id)
                      .map(cls => {
                        return (
                          <Option key={cls.id} value={cls.id} label={cls.name}>
                            {cls.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Form>
              <Stack style={{ height: '800px' }}>
                <Calendar
                  events={[...myEvents, ...holidays, ...allEvents]}
                  onEventDrop={moveEvent}
                  culture={culture}
                  messages={messages}
                  onSelectEvent={navigateToResource}
                  popup
                  resizable={false}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </div>
      <RescheduleEventModal
        loading={showRescheduleLessonSpinner}
        currentEvent={currentEvent}
        isOpen={!!currentEvent}
        onClose={hideRescheduleModal}
        onSave={rescheduleLesson}
      />
      <ResetPlannerModal isOpen={showResetPlannerModal} onClose={() => setShowResetPlannerModal(false)} />
      <TutorialVideoModal
        isOpen={showPlannerTutorial}
        onClose={() => setShowPlannerTutorial(false)}
        type="SetupPlannerDetail"
      />
    </>
  );
};
export default Planner;
